// MetaInfo.js
import { metaInfo } from "../../assets/data/meta-config";
import { Helmet } from "react-helmet-async";  // Import Helmet from react-helmet-async

export const MetaInfo = ({ page_name }) => {
  const pageMeta = metaInfo[page_name] || metaInfo['home'];
  const { title, description, keyword, url } = pageMeta;

  // Use the environment variable to dynamically set the base URL
  const baseUrl = process.env.REACT_APP_BASE_URL || 'http://localhost:3000';

  // Dynamically generate the canonical URL based on the page name
  const canonicalUrl = `${baseUrl}/${page_name}`;

  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword} />
      
      {/* Dynamically set the canonical URL */}
      <link rel="canonical" href={canonicalUrl} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={url} />
      <meta property="og:image:secure_url" content={url} />
      <meta property="og:image:type" content="image/jpg" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="628" />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={url} />
    </Helmet>
  );
};
